.awareness-page {
  background-image: url("../Assets/imgs/awareness-bg.webp");
  min-height: calc(100vh + 150px);
  padding-bottom: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f7efff;
}
.awareness-page .image {
  display: none;
}

.awareness-page .image img {
  width: 100%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background-color: white;
  padding: 30px 20px;
  border-radius: 8px;
  max-width: 330px;
  width: 100%;
  overflow: auto;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.modal-content img {
  margin-bottom: 8px;
}
.modal-content p {
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 21px;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
.terms-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-top: 15px;
}
.terms-wraper .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #097a9a99;
}
.pr-modal {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 100%;
  max-width: 75%;
  overflow: auto;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.pr-modal .modalHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pr-modal .modalHead .modal-heading {
  color: rgba(2, 126, 191, 1);
  font-size: 24px;
  font-weight: 500;
}
.pr-modal .modalHead .modal-heading span {
  color: #000;
}
.pr-modal .pr-close-modal {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
.pr-modal-content ul {
  margin-top: 20px;
  max-height: 410px;
  overflow: auto;
}
.pr-modal-content ul li {
  color: rgba(12, 61, 92, 1);
  font-size: 15px;
  font-weight: 300;
  text-align: left;
  list-style-type: disc !important;
  margin-bottom: 12px;
  margin-left: 22px;
}
@media (max-width: 767px) {
  .modal {
    max-width: 80%;
  }
  .pr-modal-content ul li {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .pr-modal-content ul {
    max-height: 320px;
  }
}


@media (max-width: 991px) {
  .awareness-page {
    background: #fff;
    min-height: 100vh;
  }
  .awareness-page .image {
    display: block;
    max-width: 375px;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .awareness-page .image {
    max-width: 100%;
    padding: 0;
  }
}
